var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"blog-banner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_c('h4',[_c('span',[_vm._v(_vm._s(_vm.$t('Blog.header')))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('Blog.subtitle'))+" ")])])])]),_c('FeatureArticle',{attrs:{"featuredArticle":_vm.featuredArticle,"titleLang":_vm.titleLang,"textLang":_vm.textLang}}),_c('section',{staticClass:"blog-main"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mx-2"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"left"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Blog.topic')))]),_c('ul',[_c('li',[_c('a',{on:{"click":_vm.searchBlogByCategories}},[_vm._v(" "+_vm._s(_vm.$t('Blog.all'))+" ")])]),(_vm.categories.length > 0)?_vm._l((_vm.categories),function(dataCategories,index){return _c('li',{key:index},[_c('a',{on:{"click":function($event){return _vm.searchBlogByCategories(
                                                dataCategories[_vm.categoryLang]
                                            )}}},[_vm._v(" "+_vm._s(dataCategories[_vm.categoryLang])+" ")])])}):_vm._l((3),function(data){return _c('li',{key:data},[_c('b-skeleton',{attrs:{"animation":"wave","width":"25%"}})],1)})],2)])]),_c('div',{staticClass:"col-lg-9"},[(_vm.article.length > 0)?_c('span',_vm._l((_vm.article),function(articleItem,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-4"},[_c('router-link',{attrs:{"to":{
                                            name: 'blogdetail',
                                            params: {
                                                slug: articleItem.slug,
                                            },
                                        }}},[_c('img',{staticClass:"main",attrs:{"src":articleItem.image}})])],1),_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"text"},[_c('router-link',{attrs:{"to":{
                                                name: 'blogdetail',
                                                params: {
                                                    slug: articleItem.slug,
                                                },
                                            }}},[_c('h4',{domProps:{"innerHTML":_vm._s(
                                                    articleItem[_vm.titleLang]
                                                )}})]),_c('div',{staticClass:"alt"},[_c('div',{staticClass:"user"},[_c('span',{domProps:{"textContent":_vm._s(
                                                        articleItem
                                                            .category[
                                                            _vm.categoryLang
                                                        ]
                                                    )}})]),_c('h6',{domProps:{"textContent":_vm._s(
                                                    _vm.getDateArticle(
                                                        articleItem.created_at
                                                    )
                                                )}})]),_c('p',{domProps:{"innerHTML":_vm._s(
                                                _vm.getSubstring(
                                                    articleItem[_vm.textLang]
                                                )
                                            )}})],1)])])])}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchArticle),expression:"searchArticle"}],staticClass:"misc-inner p-2 p-sm-3"},[(_vm.article.length == 0)?_c('div',{staticClass:"w-100 text-center"},[_c('h2',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Message.notFound'))+" 🕵🏻‍♀️ ")])]):_vm._e()])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }